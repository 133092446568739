var script_loaded = !1;
function loadJSscripts() {
  setTimeout(function () {
    if (!script_loaded) {
      script_loaded = !0;
      var t = document.getElementsByTagName("script");
      for (i = 0; i < t.length; i++)
        null !== t[i].getAttribute("data-src") &&
          (t[i].setAttribute("src", t[i].getAttribute("data-src")),
          delete t[i].dataset.src);
      var e = document.getElementsByTagName("link");
      for (i = 0; i < e.length; i++)
        null !== e[i].getAttribute("data-href") &&
          (e[i].setAttribute("href", e[i].getAttribute("data-href")),
          delete e[i].dataset.href);
      setTimeout(function () {
        document.dispatchEvent(new CustomEvent("StartAsyncLoading")),
          document.dispatchEvent(new CustomEvent("StartKernelLoading"));
      }, 400);
    }
    console.log("Shopioso 12s wait");
  }, 12e3);
}
function loadJSscriptsNow() {
  if (!script_loaded) {
    console.log("Shopioso scripts have loaded."), (script_loaded = !0);
    var t = document.getElementsByTagName("script");
    for (i = 0; i < t.length; i++)
      null !== t[i].getAttribute("data-src") &&
        (t[i].setAttribute("src", t[i].getAttribute("data-src")),
        delete t[i].dataset.src);
    var e = document.getElementsByTagName("link");
    for (i = 0; i < e.length; i++)
      null !== e[i].getAttribute("data-href") &&
        (e[i].setAttribute("href", e[i].getAttribute("data-href")),
        delete e[i].dataset.href);
    setTimeout(function () {
      document.dispatchEvent(new CustomEvent("StartAsyncLoading")),
        document.dispatchEvent(new CustomEvent("StartKernelLoading"));
    }, 400);
  }
}
var activityEvents = [
  "mousedown",
  "mousemove",
  "keydown",
  "scroll",
  "touchstart",
  "click",
  "keypress",
  "touchmove",
];
activityEvents.forEach(function (t) {
  window.addEventListener(t, loadJSscriptsNow, !1);
}),
  document.addEventListener("DOMContentLoaded", loadJSscripts),
  window.addEventListener
    ? window.addEventListener("load", loadJSscripts)
    : window.attachEvent
    ? window.attachEvent("onload", loadJSscripts)
    : (window.onload = loadJSscripts);
